<template>
  <div>
    <KTCodePreview v-bind:title="'Cấu hình thông báo loyalty'">
      <template v-slot:preview>
        <div>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <label>Tên Chương trình:</label>
                <b-form-input
                  size="sm"
                  v-model="dataset.title"
                  placeholder="Nhập tên chương trình"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label>Ngày thông báo:</label>
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dataset.notifyDate"
                ></date-picker>
              </b-form-group>
              <b-form-group>
                <label>Trạng thái:</label>
                <b-form-select
                  size="sm"
                  v-model="dataset.isActive"
                  :options="[
                    {
                      id: null,
                      name: 'Chọn trạng thái',
                    },
                    {
                      id: 1,
                      name: 'Hoạt động',
                    },
                    {
                      id: 2,
                      name: 'Không hoạt động',
                    },
                  ]"
                  class="select-style"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="5">
              <b-form-group>
                <label>Mô tả:</label>
                <b-form-textarea
                  v-model="dataset.content"
                  placeholder="Nhập mô tả"
                  rows="9"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group>
                <label>Data (JSON):</label>
                <b-form-textarea
                  v-model="dataset.data"
                  placeholder="Nhập mô tả dạng json"
                  rows="9"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <hr
            class="hr-text"
            data-content="Đối tượng thông báo"
            style="font-weight: 600"
          />
          <b-row>
            <b-col cols="3">
              <b-form-group>
                <label>Đối tượng:</label>
                <b-form-select
                  size="sm"
                  v-model="dataset.notifyTargetType"
                  :options="[
                    {
                      id: null,
                      name: 'Chọn loại đối tượng',
                    },
                    {
                      id: 1,
                      name: 'Số điện thoại',
                    },
                    {
                      id: 2,
                      name: 'Android',
                    },
                    {
                      id: 3,
                      name: 'IOS',
                    },
                  ]"
                  class="select-style"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group>
                <label>Danh mục:</label>
                <b-form-select
                  size="sm"
                  v-model="dataset.category"
                  :options="[
                    {
                      id: null,
                      name: 'Chọn loại danh mục',
                    },
                    {
                      id: 'KM',
                      name: 'Khuyến Mãi',
                    },
                    {
                      id: 'GENERAL',
                      name: 'Chung',
                    },
                  ]"
                  class="select-style"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="isShown('target-users-list', dataset.notifyTargetType)">
            <b-col cols="12">
              <b-form-group>
                <b-row>
                  <b-col cols="3">
                    <label>Số điện thoại khách hàng</label>
                    <Autosuggest
                      :model="customerSearch"
                      :suggestions="filteredOptions"
                      placeholder="sđt khách hàng"
                      :limit="200"
                      @select="onSelected"
                      @change="onInputChange"
                    >
                      <template #custom="{ suggestion }">
                        <div>
                          <span class="mr-1">{{
                            suggestion.item.phoneNo
                          }}</span>
                          <span
                            class="text-primary"
                            v-if="
                              suggestion.item.fullName &&
                              suggestion.item.fullName.trim().length > 0
                            "
                          >
                            - {{ suggestion.item.fullName }}
                          </span>
                        </div>
                      </template>
                    </Autosuggest>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <b-row>
                  <b-col cols="6">
                    <b-table
                      :fields="userFields"
                      :items="dataset.notifyTargetValue"
                      bordered
                      :per-page="pagination.perPage"
                      :current-page="pagination.currentPage"
                    >
                      <template v-slot:cell(actions)="row">
                        <span
                          style="color: #3f4254; font-size: 12px"
                          class="pointer"
                          @click="onRemoveUser(row.item.id)"
                        >
                          <i
                            style="font-size: 1rem; color: #d33"
                            class="flaticon2-rubbish-bin-delete-button"
                          ></i>
                        </span>
                      </template>
                    </b-table>
                    <b-row>
                      <b-col lg="3" md="3" sm="12">
                        <b>Tổng số: {{ dataset.notifyTargetValue.length }} </b>
                      </b-col>
                      <b-col lg="9" md="9" sm="12">
                        <b-pagination
                          pills
                          v-if="
                            dataset.notifyTargetValue.length >
                            pagination.perPage
                          "
                          v-model="pagination.currentPage"
                          :total-rows="dataset.notifyTargetValue.length"
                          :per-page="pagination.perPage"
                          aria-controls="my-table"
                          align="right"
                          size="sm"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          type="submit"
          @click="upsertNotify"
          >Lưu</b-button
        >
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          >Hủy</b-button
        >
      </template>
    </KTCodePreview>
  </div>
</template>
<style>
.form-group label {
  font-weight: 600;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
import moment from 'moment';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { makeToastFaile } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { TIME_TRIGGER } from '@/utils/constants';
import { debounce } from 'lodash';

export default {
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      dataset: {
        id: null,
        title: null,
        content: null,
        isActive: null,
        notifyDate: null,
        notifyTargetType: 1,
        notes: null,
        status: 1,
        data: null,
        notifyTargetValue: [],
        category: null,
      },
      id: null,
      userFields: [
        {
          key: 'fullName',
          label: 'Tên khách hàng',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'phoneNo',
          label: 'Số điện thoại khách hàng',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: ' ',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
      customerSearch: null,
      filteredOptions: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
      },
    };
  },
  components: {
    KTCodePreview,
    Autosuggest,
    datePicker,
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.getById(this.id);
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cấu hình thông báo loyalty', route: '/loyalty-notifies' },
    ]);
  },
  methods: {
    isShown(mode, value) {
      switch (mode) {
        case 'target-users-list': {
          return value === 1 ? true : false;
        }
      }
    },
    convertData(dataset) {
      const notifyDate = dataset.notifyDate || null;
      const notifyTargetValue = this.dataset.notifyTargetValue || [];
      return {
        ...dataset,
        notifyDate: notifyDate
          ? moment(notifyDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        notifyTargetValue: notifyTargetValue,
      };
    },
    validatePayload(dataset) {
      if (dataset.notifyTargetType === 1 && !dataset.notifyTargetValue.length) {
        return [false, 'Vui lòng chọn số điện thoại khách hàng'];
      }
      if (!this.isJSON(dataset.data)) {
        return [false, 'Không đúng định dạng JSON'];
      }
      return [true, 'Dữ liệu hợp lệ'];
    },
    isJSON(value) {
      try {
        const isObject =
          value.slice(0, 1) === '{' && value.slice(value.length - 1) === '}';
        if (typeof value === 'string' && isObject) {
          JSON.parse(value);
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
      return true;
    },
    upsertNotify() {
      const id = this.id || null;
      const [isValid, message] = this.validatePayload(this.dataset);
      if (!isValid) {
        return makeToastFaile(message);
      }
      const method = !id ? 'post' : 'put';
      const data = this.convertData(this.dataset);
      ApiService[method]('/loyalty-notifies/', data)
        .then(() => {
          this.$router.push({
            name: 'loyalty-notifies-list',
          });
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
    getById: async function (id) {
      ApiService.query(`/loyalty-notifies/${id}`)
        .then((response) => {
          const respData = response.data.data;
          this.dataset = respData;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
    debounceInput: debounce((textInput, fn) => {
      fn(textInput);
    }, TIME_TRIGGER),
    searchCustomerAPI(textInput) {
      this.fetchCustomers(textInput);
    },
    fetchCustomers: function (textSearch) {
      ApiService.query(`customer/get-by-phone`, {
        params: {
          phoneNumber: textSearch,
        },
      }).then((response) => {
        const { data } = response.data;
        this.filteredOptions = data;
      });
    },
    onSelected(option) {
      const userAdded = this.findUserById(
        option.item.id,
        this.dataset.notifyTargetValue
      );
      if (userAdded) {
        makeToastFaile('Số điện thoại đã tồn tại');
      } else {
        this.dataset.notifyTargetValue.push(option.item);
      }
    },
    onInputChange(textInput) {
      this.debounceInput(textInput, this.searchCustomerAPI);
    },
    onRemoveUser(id) {
      const index = this.dataset.notifyTargetValue.findIndex(
        (user) => user.id === id
      );

      if (index > -1) {
        this.dataset.notifyTargetValue.splice(index, 1);
        this.pagination.currentPage = 1;
      }
    },
    findUserById(id, list) {
      return list.find((user) => user.id === id);
    },
  },
  computed: {},
};
</script>

