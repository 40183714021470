var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('KTCodePreview',{attrs:{"title":'Cấu hình thông báo loyalty'},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Tên Chương trình:")]),_c('b-form-input',{attrs:{"size":"sm","placeholder":"Nhập tên chương trình"},model:{value:(_vm.dataset.title),callback:function ($$v) {_vm.$set(_vm.dataset, "title", $$v)},expression:"dataset.title"}})],1),_c('b-form-group',[_c('label',[_vm._v("Ngày thông báo:")]),_c('date-picker',{staticClass:"form-control form-control-sm",attrs:{"placeholder":"Từ ngày","config":_vm.dpConfigs.date},model:{value:(_vm.dataset.notifyDate),callback:function ($$v) {_vm.$set(_vm.dataset, "notifyDate", $$v)},expression:"dataset.notifyDate"}})],1),_c('b-form-group',[_c('label',[_vm._v("Trạng thái:")]),_c('b-form-select',{staticClass:"select-style",attrs:{"size":"sm","options":[
                  {
                    id: null,
                    name: 'Chọn trạng thái',
                  },
                  {
                    id: 1,
                    name: 'Hoạt động',
                  },
                  {
                    id: 2,
                    name: 'Không hoạt động',
                  },
                ],"value-field":"id","text-field":"name"},model:{value:(_vm.dataset.isActive),callback:function ($$v) {_vm.$set(_vm.dataset, "isActive", $$v)},expression:"dataset.isActive"}})],1)],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',[_c('label',[_vm._v("Mô tả:")]),_c('b-form-textarea',{attrs:{"placeholder":"Nhập mô tả","rows":"9"},model:{value:(_vm.dataset.content),callback:function ($$v) {_vm.$set(_vm.dataset, "content", $$v)},expression:"dataset.content"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Data (JSON):")]),_c('b-form-textarea',{attrs:{"placeholder":"Nhập mô tả dạng json","rows":"9"},model:{value:(_vm.dataset.data),callback:function ($$v) {_vm.$set(_vm.dataset, "data", $$v)},expression:"dataset.data"}})],1)],1)],1),_c('hr',{staticClass:"hr-text",staticStyle:{"font-weight":"600"},attrs:{"data-content":"Đối tượng thông báo"}}),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Đối tượng:")]),_c('b-form-select',{staticClass:"select-style",attrs:{"size":"sm","options":[
                  {
                    id: null,
                    name: 'Chọn loại đối tượng',
                  },
                  {
                    id: 1,
                    name: 'Số điện thoại',
                  },
                  {
                    id: 2,
                    name: 'Android',
                  },
                  {
                    id: 3,
                    name: 'IOS',
                  },
                ],"value-field":"id","text-field":"name"},model:{value:(_vm.dataset.notifyTargetType),callback:function ($$v) {_vm.$set(_vm.dataset, "notifyTargetType", $$v)},expression:"dataset.notifyTargetType"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Danh mục:")]),_c('b-form-select',{staticClass:"select-style",attrs:{"size":"sm","options":[
                  {
                    id: null,
                    name: 'Chọn loại danh mục',
                  },
                  {
                    id: 'KM',
                    name: 'Khuyến Mãi',
                  },
                  {
                    id: 'GENERAL',
                    name: 'Chung',
                  },
                ],"value-field":"id","text-field":"name"},model:{value:(_vm.dataset.category),callback:function ($$v) {_vm.$set(_vm.dataset, "category", $$v)},expression:"dataset.category"}})],1)],1)],1),(_vm.isShown('target-users-list', _vm.dataset.notifyTargetType))?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('label',[_vm._v("Số điện thoại khách hàng")]),_c('Autosuggest',{attrs:{"model":_vm.customerSearch,"suggestions":_vm.filteredOptions,"placeholder":"sđt khách hàng","limit":200},on:{"select":_vm.onSelected,"change":_vm.onInputChange},scopedSlots:_vm._u([{key:"custom",fn:function({ suggestion }){return [_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(suggestion.item.phoneNo))]),(
                            suggestion.item.fullName &&
                            suggestion.item.fullName.trim().length > 0
                          )?_c('span',{staticClass:"text-primary"},[_vm._v(" - "+_vm._s(suggestion.item.fullName)+" ")]):_vm._e()])]}}],null,false,1152957599)})],1)],1)],1),_c('b-form-group',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-table',{attrs:{"fields":_vm.userFields,"items":_vm.dataset.notifyTargetValue,"bordered":"","per-page":_vm.pagination.perPage,"current-page":_vm.pagination.currentPage},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('span',{staticClass:"pointer",staticStyle:{"color":"#3f4254","font-size":"12px"},on:{"click":function($event){return _vm.onRemoveUser(row.item.id)}}},[_c('i',{staticClass:"flaticon2-rubbish-bin-delete-button",staticStyle:{"font-size":"1rem","color":"#d33"}})])]}}],null,false,537852165)}),_c('b-row',[_c('b-col',{attrs:{"lg":"3","md":"3","sm":"12"}},[_c('b',[_vm._v("Tổng số: "+_vm._s(_vm.dataset.notifyTargetValue.length)+" ")])]),_c('b-col',{attrs:{"lg":"9","md":"9","sm":"12"}},[(
                          _vm.dataset.notifyTargetValue.length >
                          _vm.pagination.perPage
                        )?_c('b-pagination',{attrs:{"pills":"","total-rows":_vm.dataset.notifyTargetValue.length,"per-page":_vm.pagination.perPage,"aria-controls":"my-table","align":"right","size":"sm"},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}}):_vm._e()],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)]},proxy:true},{key:"foot",fn:function(){return [_c('b-button',{staticStyle:{"fontweight":"600","width":"70px"},attrs:{"variant":"primary","size":"sm","type":"submit"},on:{"click":_vm.upsertNotify}},[_vm._v("Lưu")]),_c('b-button',{staticStyle:{"margin-left":"10px","font-weight":"600","width":"70px"},attrs:{"variant":"secondary","size":"sm"}},[_vm._v("Hủy")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }